.child-page {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .child-body {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }

  .main-table {
    flex-grow: 1;
    overflow: auto;
  }
}

.data_change_sections {
  background-color: #f1f4f8;
  padding: 0 20px 0 20px;

  .child-page__forms {
    height: 40px;
  }

  .child-page__tools {
    height: 40px;
    align-items: center;

    button {
      height: 20px;
      width: 20px;
      margin-right: 20px;
      padding: 0;
      outline: none;
      background: none;
      border: none;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    button:nth-child(6) {
      height: 17px;
    }
  }
}

.hodimlar {
  background-image: url("../../../assets/icons/staff.svg");
}
