#root {
  height: 100%;
}

// __________________________Scroll-bar start
*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1890ff;
  background-color: rgba(90, 90, 90, 0.3);
  border-radius: 3px;
}

// __________________________Scroll-bar end

.site-container {
  height: 100%;
  //border: 3px solid red;
  overflow: hidden;
  padding: 0 !important;
  // __________________________Site Header start
  .site-header {
    background-color: #fff;
    position: fixed;
    z-index: 500;
    width: 100%;
    height: 55px;
    // border: 1px solid red;
    display: flex;
    padding: 0;

    .header__logo {
      text-align: center;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }

    .header__navigation {
      justify-content: center;
      flex-grow: 1;
      align-items: center;

      .ant-menu-item {
        height: 100%;
        display: flex;
        align-items: center;

        a {
          letter-spacing: 0.03em;
          color: #bac8dc;
        }

        svg {
          position: relative;
          top: 2px;

          path {
            transition: 0.3s;
            fill: #bac8dc;
          }
        }
      }

      .activeStyle {
        border-bottom: 2px solid #1890ff;
        padding-bottom: 16px;
        color: #1890ff;

        span {
          color: #1890ff;
        }

        svg {
          path {
            fill: #1890ff;
          }
        }
      }

      .header__navigation__item:first-child {
        margin-left: -100px;
      }
    }

    .header__user-profile {
      text-align: center;
      padding: 0 20px;
      display: flex;
      align-items: center;

      .user-profile-search {
        display: block;
        background: rgba(186, 200, 220, 0.2);
        border-radius: 10px;
        outline: none;
        border: none;
      }

      .user-profile-image {
        cursor: pointer;
        transform: translate(10px, -5px);
      }
    }
  }

  // __________________________Site Header end

  // __________________________Site Layout start
  .site-layout {
    background-color: #eaedf2;
    margin-top: 54px !important;
  }
  .ant-modal-content {
    display: none !important;
  }
  // __________________________Site Layout end

  // __________________________Site Footer start
  .site-footer {
    background-color: #fff;
    padding: 0;

    .site-footer__content {
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .site-footer__icons {
        padding-top: 5px;
      }

      .site-footer__text {
        color: #9dbeef;
        font-size: 1.1em;
      }

      .site-footer-clock {
        font-size: 1.2em;
        color: var(--main-color);
        font-weight: 600;
      }
    }
  }

  // __________________________Site Footer end
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

.location {
  .ant-modal-footer {
    display: none !important;
  }
  .ymaps-2-1-79-gotoymaps__text {
    display: none !important;
  }
  .ymaps-2-1-79-map-copyrights-promo {
    display: none !important;
  }
  .ymaps-2-1-79-copyright__content-cell {
    display: none !important;
  }
}
