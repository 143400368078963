.global-modal,
.inner-modal {
  .modal-header__buttons {
    .toolbar__buttons {
      button:nth-child(1),
      button:nth-child(2) {
        display: none;
      }
    }
  }

  .ant-modal-title {
    background-color: var(----main-color);
  }

  .ant-modal-content {
    border-radius: 15px;
    margin: 0 !important;
  }

  .ant-modal-body {
    padding: 0;
    padding-bottom: 1px;
    margin: auto 0;
  }

  .ant-modal-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0 !important;
    overflow: hidden;

    .modal-header {
      width: 100%;
      background-color: var(--main-color);
      height: 35px;
      padding: 0 15px 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;

      .modal-header__buttons {
        height: 20px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .modal-header__button {
          background-color: var(--main-color);
          outline: none;
          height: 15px;
          width: 15px;
          padding: 0;
          position: relative;
          margin-left: 5px;

          svg {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .modal-form {
    align-items: center;
    justify-content: center;
    margin: 30px;

    .ant-form-item {
      margin: 0;

      .ant-form-item-label {
        text-align: start;

        label {
          height: 0;
        }
      }
    }

    .modal-grid__form {
      width: 100%;
      display: grid;
      grid-gap: 10px 20px;
      .ant-input {
        width: 100%;
        height: 35px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        outline: none;
      }

      .ant-input-password {
        border: 1px solid #d9d9d9;
        width: 100%;
        height: 35px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        outline: none;
        .ant-input {
          border-bottom: 1px solid #d9d9d9;
          &:focus {
            border-color: #57a8e9;
            outline: none;
            box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
          }
          cursor: pointer;
          &:hover {
            border: 1px solid #57a8e9;
            outline: none;
          }

          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .select-add__option {
        display: flex;

        .option-add {
          border: 1px solid #d9d9d9;
          border-left: none;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 33px;
          right: 0;
          cursor: pointer;
        }
      }

      .ant-input-group {
        display: flex !important;
        flex-direction: column;

        .ant-input-group-addon {
          background-color: transparent !important;
          width: 100%;
          font-size: 0.98em;
          text-align: left;
          margin-bottom: 2px;
          padding-left: 0;
        }
      }

      .modal-inputs__label {
        font-size: 0.98em;
        height: 100% !important;
      }

      .avatar-uploader {
        width: 100% !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        outline: none;
        margin-top: 20px;
      }

      .ant-upload {
        width: 100% !important;
      }

      .ant-upload.ant-upload-select-picture-card {
        border: 1px solid #d9d9d9;
        &:hover {
          border: 1px solid #57a8e9;
          box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
        }
      }

      .file-uploader-label {
        margin-top: 22px;
        width: 100% !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #d9d9d9;

        &:focus {
          border-color: #57a8e9;
          outline: 0;
          box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
        }

        cursor: pointer;

        &:hover {
          border: 1px solid #57a8e9;
          outline: 0;
          -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
          box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
        }

        .ant-upload-picture-card-wrapper {
          height: 100%;

          .ant-upload-list {
            height: 100%;

            .ant-upload {
              height: 100%;
            }

            .ant-upload-list-picture-card-container {
              height: 100%;
              width: 100%;

              .ant-tooltip {
                display: none !important;
              }
            }
          }
        }

        p {
          color: black !important;
          padding-bottom: 10px;
          transform: translateY(-22px);
          text-align: start;
          font-size: 14px;
        }

        .file-uploader__icon {
          height: 5px;
        }
      }

      #file-uploder {
        display: none;
      }

      .image-input {
        margin-top: 22px;
      }

      .ant-input-number {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      .ant-select-single {
        height: 35px;
        width: 100%;
        font-weight: 400;
        display: flex;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      .ant-select-selector {
        height: 35px !important;
        display: flex;
        align-items: center;
        outline: none;
      }

      .ant-picker {
        width: 100%;
        height: 35px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      .react-tel-input {
        width: 100%;
        height: 35px !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .form-control {
          width: 100%;
          height: 35px;
          outline: none;
          padding-left: 10px;
          &:hover {
            border-color: #57a8e9;
          }
          &:focus {
            border-color: #57a8e9;
            outline: 0;
            box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
          }
        }
      }
    }

    .modal-form_buttons {
      display: flex;
      max-width: 350px;
      margin: 0 auto;
      align-items: center;
      gap: 0 7px;
      justify-content: space-evenly;
      margin-top: 30px;

      .modal-form__button {
        width: 150px;
        border: none;
        outline: none;
        height: 35px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        cursor: pointer;
      }

      .qaytish {
        border: 1px solid var(--main-color);
        color: var(--main-color);
      }

      .saqlash {
        color: #ffffff;
        background-color: var(--main-color);
      }
    }
  }

  // map

  .ymaps-2-1-79-events-pane .ymaps-2-1-79-user-selection-none {
    border: 1px solid red !important;
  }

  .ymaps-2-1-79-map-copyrights-promo {
    display: none;
  }

  .file-uploader-label {
    border-radius: 2px;
    .ant-upload-select-text {
      display: block;
    }
  }

  .modal_input {
    width: 95% !important;
    display: block !important;
    margin: 0 auto !important;
  }

  .modal__form {
    width: 95% !important;
    margin: 0 auto;
    margin-top: 20px;
  }

  .phone_input {
    input {
      width: 95%;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid #40a9ff;
      }

      &:focus {
        outline: 1px solid #40a9ff;
      }
    }
  }

  .react-tel-input {
    input {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}

.delete-file-btn {
  border: 1px solid #ccc;
  background-color: rgb(244, 244, 244);
  width: 23px;
  border-radius: 2px;
  cursor: pointer;
  height: 23px;
  padding: 2px;
  svg {
    margin-left: -2px;

    path {
      fill: #888;
    }
  }

  &:hover {
    background-color: rgb(251, 251, 251);
    border-color: #fa4b4b;

    svg {
      path {
        fill: #fa4b4b;
      }
    }
  }
}

.ant-modal-mask {
  background-color: transparent !important;
  margin: 290px;
  height: 0;
  width: 0;
  overflow: visible;
  padding: 0 !important;
}

.ant-modal-wrap {
  width: 100%;
  height: 0;
  overflow: visible;
  padding: 0 !important;
}

.ant-modal {
  display: flex !important;
  justify-content: center;
}

.previewModal {
  width: 500px !important;
  height: 600px;
  .modalImg {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }
}
.file-uploader-name {
  width: 100%;
  height: 18px;
  overflow: hidden;
}

.file-uploader-span {
  word-break: break-all;
  width: 200px;
  font-size: 14px;
  text-align: center;
}
