:root {
  --main-color: #4447e2;
  --second-color: #1890ff;
}

.site-layout__body {
  overflow: scroll;
}
.undefined {
  background-color: rgb(236, 151, 119) !important;
  padding: 0 3px;
}
.hodim-template {
  height: 35px;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 17px;
  position: relative;
}
.hodim-template > .box-shadow {
  position: absolute;
  bottom: 0;
  height: 15px;
  width: 100%;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.74);
}
.ant-tabs-tab {
  a {
    display: block;
    width: 100%;
    height: 25px;
  }
}

/* ------------------------------------ / ----------------------------------- */
#site__loyout {
  .my__layout_child {
    margin: 0 auto;
    background-color: #fff;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: none !important;
  border-right: 1px solid #999 !important;
}
.img__skelaton{
 position: absolute;
 z-index: 15;
 margin-left: -8px;
 width: 30px;
 .ant-skeleton-avatar {
   width: 30px;
 }
}